import "./src/css/style.css";
import cssVars from "css-vars-ponyfill";

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  cssVars();

  // let freeze = false;

  // const setViewPortHeightCssVar = () => {
  //   if (!freeze) {
  //     // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  //     let vh = window.innerHeight * 0.01;
  //     // Then we set the value in the --vh custom property to the root of the document
  //     document.documentElement.style.setProperty("--vh", `${vh}px`);
  //   }
  // };

  // setViewPortHeightCssVar();
  // // We listen to the resize event
  // window.addEventListener("resize", setViewPortHeightCssVar);

  // document.addEventListener("virtualKeyboardOpening", (e) => {
  //   freeze = true;
  // });

  // document.addEventListener("virtualKeyboardClosing", (e) => {
  //   freeze = false;
  // });

  // window.addEventListener("orientationchange", (e) => {
  //   freeze = false;
  // });
};
